import "./gallery.scss";
import { SliderData } from "../shared/slider_data";
import React from "react";
import SideMenu from "../shared/sidemenu/sidemenu";

let arr = SliderData;

function Gallery() {
  return (
    <div className="gallery">
      <SideMenu></SideMenu>
      <div className="spacer"></div>
      <div className="photos">
        {arr.map((image) => (
          <img
            key={image}
            loading="lazy"
            className={image.orientation}
            src={image.image}
            alt=""
          ></img>
        ))}
      </div>
    </div>
  );
}

export default Gallery;
