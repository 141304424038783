import './footer.scss'
import React, { Component} from 'react'

import spotify from '../../assets/spotify.svg';
import insta from '../../assets/insta.svg';

class Footer extends Component {

  render() {
    return(
    <div className="footer">
      <div className="socials">
        <a target="_blank" href="https://github.com/robinojw"><p>Github</p></a>
        <div className="icons">
          <a href="https://open.spotify.com/user/1119931584?si=hqPoITZTSZ2EvnQ1FXU4_Q" target="_blank"><img src={spotify} alt=""/></a>
          <a href="https://www.instagram.com/robinojw/?hl=en" target="_blank"><img src={insta} alt=""/></a>
        </div>
      </div>
      <p className="work">Software Engineer @ <a target="_blank" href="https://onepeloton.co.uk">Peloton 🚲</a></p>
    </div>
    )
  }
}

export default Footer;
