import React from "react";
import SideMenu from "../shared/sidemenu/sidemenu";
import "./projects.scss";
import Project from "./project/project";
import ProjectData from "../models/projects";
let arr = ProjectData;

function Projects() {
  return (
    <div className="projects">
      <SideMenu></SideMenu>
      <div className="spacer"></div>
      <div className="projects-list">
        {arr.map((project, index) => (
          <Project key={index} data={project}></Project>
        ))}
      </div>
    </div>
  );
}

export default Projects;
