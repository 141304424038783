import "./content.scss";
import React, { Component } from "react";
import Tech from "../tech/tech";

class Content extends Component {
  content = this.props.data.case;

  render() {
    return (
      <div className="case-content">
        {this.content.case.titles.map((title, index) => (
          <>
            <div key={index} className="content-image">
              {this.content.case.images[index] ? (
                <img
                  src={this.content.case.images[index]}
                  loading="lazy"
                  alt=""
                />
              ) : (
                <div />
              )}
            </div>
            <div className="content-info">
              <h2>{title}</h2>
              <div className="description">
                <p>{this.content.case.description[index]}</p>
                {index == 0 ? <Tech data={this.content.tech} /> : <div />}
              </div>
            </div>
          </>
        ))}
        <a href={this.content.link} target="_blank" rel="noopener norefferer">
          <div className="button">
            <p>Visit Site</p>
            <div className="button-underlay"></div>
          </div>
        </a>
      </div>
    );
  }
}

export default Content;
