import "./header-image.scss";
import React, { Component } from "react";
import { Link } from "react-router-dom";

class HeaderImage extends Component {
  content = this.props.data.case;

  backgroundStyle = {
    background: this.content.color.background,
  };

  titleStyle = {
    color: this.content.color.title,
  };

  render() {
    return (
      <div className="header-img">
        <div className="caption back">
          <Link to="/projects">← back</Link>
        </div>
        <div className="ani" style={this.backgroundStyle}>
          <h2 style={this.titleStyle}>{this.content.name}</h2>
          <img src={this.content.image} alt="" />
        </div>
        <div className="caption">
          <p>{this.content.client}</p>
          <p>{this.content.date}</p>
        </div>
      </div>
    );
  }
}

export default HeaderImage;
