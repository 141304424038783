import "./header.scss";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Hamburger from "../../shared/hamburger/hamburger";

class Header extends Component {
  render() {
    return (
      <div className="header">
        <div className="nav">
          <div className="nav-head">
            <h2 className="nav-title">robin</h2>
            <p>Software Engineer</p>
            <p className="location">North London, UK</p>
          </div>
          <Hamburger></Hamburger>
          <ul>
            <Link to="/projects">
              <li>Projects</li>
            </Link>
            <Link to="/about">
              <li>About</li>
            </Link>
          </ul>
        </div>
      </div>
    );
  }
}

export default Header;
