import "./tech.scss";
import React, { Component } from "react";

class Tech extends Component {
  content = this.props.data;
  tech = this.content.split(",");

  render() {
    return (
      <div className="tech">
        <p>Stack:</p>
        {this.tech.map((lang, index) => (
          <p key={index}>{lang}</p>
        ))}
      </div>
    );
  }
}

export default Tech;
