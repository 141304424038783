import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Landing from "./landing/landing";
import About from "./about/about";
import "./App.scss";
import Projects from "./projects/projects";
import Gallery from "./gallery/gallery";
import CaseStudy from "./shared/casestudy/casestudy";

import ProjectData from "./models/projects";

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/projects" component={Projects} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/about" component={About} />
          {ProjectData.map((index) => (
            <Route
              key={index.link}
              path={index.page}
              render={(props) => {
                return <CaseStudy case={index} {...props} />;
              }}
            />
          ))}
        </Switch>
      </div>
    </Router>
  );
}

export default App;
