import React from 'react'
import Footer from './footer/footer';
import Header from './header/header'
import Slider from './slider/slider';
import './landing.scss';

function Landing() {
  return (
    <div id="landing">
    <Header></Header>
    <Slider></Slider>
    <Footer></Footer>
   </div>
  );
}

export default Landing
