import "./project.scss";
import React, { Component } from "react";
import { Link } from "react-router-dom";

class Project extends Component {
  render() {
    const name = this.props.data.name;
    const date = this.props.data.date;
    const image = this.props.data.image;
    const page = this.props.data.page;
    const client = this.props.data.client;
    const tech = this.props.data.tech;
    const link = this.props.data.link;

    const imageStyle = {
      background: `url(${image}) no-repeat center top`,
      backgroundSize: `cover`,
    };

    return (
      <div className="project">
        <div className="info">
          <Link to={page} className="mob-link">
            <div className="project-title">
              <h2>{name}</h2>
              <p>{date}</p>
            </div>
          </Link>
          <div className="view-project">
            <a href={link} target="_blank">
              View Project →
            </a>
            <Link to={page}>
              <div className="client">
                <div className="above">
                  <p>Client</p>
                  <p>{client}</p>
                </div>
                <div className="below">
                  <p>Technology</p>
                  <p>{tech}</p>
                </div>
              </div>
            </Link>
          </div>
        </div>
        <Link to={page} className="link">
          <div className="image" style={imageStyle}>
            <div className="overlay"></div>
          </div>
        </Link>
      </div>
    );
  }
}

export default Project;
