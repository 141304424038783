import stopsearchWall from "../assets/stopsearch-wall.jpg";
import oxford from "../assets/oxford-social.jpeg";
import three from "../assets/three.gif";
import iar from "../assets/iamremarkable.jpg";
import nfl from "../assets/nfl-vr.png";
import alike from "../assets/alike.gif";
import alike_cap from "../assets/alike-capture.jpg";
import ti from "../assets/travel-insights.gif";
import blobs from "../assets/blobs.gif";
import impact from "../assets/impact.jpg";

import insights_concept from '../assets/Insights-concept.png';
import impact_concept from '../assets/Impact-Concept.png';
import improve_concept from '../assets/improve-concept.png';

import bia from '../assets/video-thumb.png';
import bia_landing from '../assets/bia-landing.png';
import bia_chapter from '../assets/bia-chapter.png';

import travel from '../assets/ti.png';
import ti_hero from '../assets/ti_hero.png'

import iar_week from "../assets/week-footer.jpg";
import iar_landing from "../assets/landing.jpg";

import stop_landing from "../assets/stop_landing.png";
import stop_query from "../assets/stop_query.png";
import stop_resources from "../assets/stop_resources.png";

import donothing_landing from '../assets/donothing_landing.png';
import donothing_stories from '../assets/donothing_stories.png';

var ProjectData = [
  {
    name: "Be Internet Awesome",
    date: "2021",
    image: bia,
    page: "/be-internet-awesome",
    client: "Google Italia",
    tech: "Jinja 2, TypeScript, Python",
    link: "https://beinternetawesome.withgoogle.com/it_it/interlandavventuredigitali/",
    color: {
      title: "#c88719",
      background: "#ffb74d",
    },
    case: {
      subtitle: " Full Stack Development ",
      titles: ["Technology", "Implementation"],
      description: [
        "Google Italia approached us asking to build an interactive site that mimiqued a modern childrens book. With interactive videos, animations and support for all kinds of devices children could read along with the videos while the pages animations helped tell the story, educating children on how to safely use the internet.",
        "The site needed to support a large range of devices and with the amount of animations, it opposed some interesting challenges. I built a custom carousel and animation 'engine' to control how parrallax effects and lottie animations were loaded onto the page, gradually loading the chapters of the book as the user progressed through the chapters. The particles we're also extremely fun to build, randomly animated, coloured and positioned using Sass mixins, they help bring the book to life!",
      ],
      images: [bia_landing, bia_chapter],
    },
  },
  {
    name: "Alike",
    date: "2021",
    image: blobs,
    page: "/alike",
    client: "Alike",
    tech: "React, NextJS, TypeScript",
    link: "https://alike.org.uk",
    color: {
      title: "#338C95",
      background: "#2DC1D0",
    },
    case: {
      subtitle: " Full Stack Development ",
      titles: ["Technology", "Aim", "Implementation"],
      description: [
        "We knew Alike had the potential to be a big platform so the apps website needed to be fast light and an extension of Alike's brand and message. We chose a stack of React, Next JS and TypeScript as we needed the app to be fast but offer the same interactivity as a single page app or PWA.  This stack gave us great performance and a structure that wasn't too restrictive but still enforced type safety and gave us room to scale should we need too.",
        "Alike's message and brand was extremely important to us and ensuring that the people who built this community were recognised was a crucial part of the sites design. The soft flow of colour and three distinct blobs on each page represent people coming together and the community that Alike's app has founded. Our aim was to create a location on the web where Alike could recognise their team, advertise their app and promote/advertise future of what they wanted Alike to be.",
        "Building this site was a huge challenge, with only two weeks before the app launched and a design that hadn't been confirmed, time was not on our side. I started by selecting a stack I felt would suit the project and built out the sites basic frame, creating demo animations and concepts that we could later show to the client. After almost a week of design and aniamtion tweeks I set out to build the site and did so with only 9 days before the application and websites launch. Luckily I delivered and have been super proud of the sites impact on Alike and it's community.",
      ],
      images: [blobs, alike, blobs],
    },
  },
  {
    name: "Travel Insights",
    date: "2020",
    image: ti,
    page: "/travel-insights",
    client: "Google",
    tech: "Jinja 2, TypeScript, Python",
    link: "https://travelinsights.withgoogle.com",
    color: {
      title: "#FFFFFF",
      background: "#0000ff",
    },
    case: {
      subtitle: " Front End Development ",
      titles: ["Technology", "Aim"],
      description: [
        "A requirement for this site was to make it lightweight, fast and easy to use. With the requirements of the site being very basic we chose to avoid heavier unessary frameworks like Angular or React and go with a lighter stack that still offered a reasonable amount of flexibility should the site expand in the future",
        "Travel Insights was one of my first projects a Phantom. A simple lightweight website dedicated to directing users to Google`s travel industry services and platforms",
      ],
      images: [travel, ti_hero],
    },
  },
  {
    name: "#IamRemarkable",
    date: "2020",
    image: iar,
    page: "/iamremarkable",
    client: "Google",
    tech: "Angular, Python",
    link: "https://iamremarkable.withgoogle.com",
    color: {
      title: "#FFFFFF",
      background: "#02075D",
    },
    case: {
      subtitle: " Full Stack Development ",
      titles: ["Technology", "Aim", "Implementation"],
      description: [
        "This was a site that started as a single page site and has grew into a 30+ page app with member sections/dashboards and pages that are enabled/disabled depending on the time of year. The inital design hadn't accounted for this level of growth and subsequnetly the sites performance made it almost unuseable on mobile. I started by upgrading the current codebase to a more recent version of angular and moving webpack 4. Due to the sites seo functionality it wasnt possible to lazy load modules or components so to reduce the bundle size I used a combination of the Angular Ivy engine and ahead of time compilation to bring the initial main js chunk down.",
        "The aim of this project was purely to provide a better user experience and increase performance wherever possible. Google was hosting IamRemarkable Week at the end of this process and with the experience this year being entirely online we needed to ensure this site broke a certain lighthouse performance barrier that would class it as a progressive web app. ",
        "The main performance increases during this process came from switching to using WebP images throughout the site, updating the compiler, implementing lazy loaded feature modules and splitting larger pages down into more reusable components so we could identify uneeded or unecessary code in what is a very large codebase.",
      ],
      images: [iar, iar_landing, iar_week],
    },
  },
  {
    name: "Stop and Search",
    date: "2020",
    image: stopsearchWall,
    page: "/stop-and-search",
    client: "Phantom",
    tech: "Angular, Python",
    link: "https://stopsearch.uk",
    color: {
      title: "#FFFFFF",
      background: "#0000ff",
    },
    case: {
      subtitle: " Front End Development ",
      titles: ["Technology", "Aim", "Impact"],
      description: [
        "Stop and Search was an incredibly fun site to work on, and one of my first when starting at Phantom. It opposed some interesting technical challenges, using a large number of animations, all of which we're activated on scroll.",
        "The real aim of this site was to help educate people on the level of systemic racism that exists in the UK. The design included a list of search queries that when clicked would trigger Google searches listing educational articles and information on this topic.",
        "This site was a real learning process for me, I chose to build the site using Angular and quickly missed features in React that would have made the process a lot simpler. I'd definitley opt to build this using a stack of React, Styled Components and TypeScript in the future.",
      ],
      images: [stop_landing, stop_query, stop_resources],
    },
  },
  {
    name: "Do Nothing",
    date: "2020",
    image: oxford,
    page: "/donothing",
    client: "Oxford Mindfulness",
    tech: "HTML, JavaScript",
    link: "https://donothing.uk",
    color: {
      title: "#FFFFFF",
      background: "#0000ff",
    },
    case: {
      subtitle: " Front End Development ",
      titles: ["Technology", "Aim"],
      description: [
        "A requirement for this site was to make it lightweight, fast and easy to use. With the requirements of the site being very basic we chose to avoid heavier unessary frameworks and libraries like Angular or React and go with a lighter stack that still offered a reasonable amount of flexibility should the site expand in the future.",
        "Oxford University's department of psychology was looking to offer a more comprehensive list of resources to support their students mental health. The site listed a range of custom made medidation videos, carerfully chosen books on mindfulness and some general tips on how to manage stress while at university.",
      ],
      images: [donothing_landing, donothing_stories],
    },
  },
  {
    name: "Impact",
    date: "2019",
    image: impact,
    page: "/impact",
    client: "Personal Project",
    tech: "Flutter, Firebase",
    link: "https://github.com/robinojw/impact",
    color: {
      title: "#FFFFFF",
      background: "#0000ff",
    },
    case: {
      subtitle: " Full Stack Development ",
      titles: ["Aim", "Implementation", "Impact"],
      description: [
        "Impact was my dissertation project at university, the idea was to build a carbon emissions tracking app that utilised, GPS, barcode scanning and some machine learning tools to accurately track a users daily carbon emissions and recommend them ways to reduce their impact. Impact also featured a marketplace where users could buy low impact products and see how switching to certain products could reduce their daily emissions.",
        "I chose a stack of Firebase and Flutter as it allowed me to offer the application to both Android and iOS users without sacrificing on performance like some React Native applications. This implementation was also key to maintaining a high velocity when working on my own, I didn’t want to spend days setting up a back end database and needed the flexibility to expand or modify the back end fairly quickly to keep up with new features on the front end.",
        "After presenting the application at my dissertation review I received a first and innovation award for the project, receiving funding from the university to develop it further into the summer. Although I chose to start work rather than develop the application further I still plan to pick it up in the future and add a web tool for the platform.",
      ],
      images: [insights_concept, impact_concept, improve_concept],
    },
  },
  {
    name: "NFL VR",
    date: "2018-2019",
    image: nfl,
    page: "/nfl",
    client: "NFL - Zebra Technologies",
    tech: "Android, Unity, RF",
    link: "https://github.com/robinojw/nfl-vr",
    color: {
      title: "#FFFFFF",
      background: "#0000ff",
    },
    case: {
      subtitle: " App Development ",
      titles: ["Aim", "Implementation", "Implementation", "Impact"],
      description: [
        "This collaboration with the NFL was one of my longer term projects I developed while interning at Zebra Technologies. Zebra was building a web platform that allowed coaches to record and review training sessions on a 2D interface and the CTO approached me to take this technology and apply it to Virtual Reality.",
        "I utilised Unity and Android VR to build out a basic play viewer that would allow users to navigate their way through plays, review passes and tackles from the perspective of players and plan new plays based on previously recorded games. The tool was then debuted at the company’s annual sales kick off and added to the company’s sports division for further development."
      ],
      images: [nfl],
    },
  },
];

export default ProjectData;
