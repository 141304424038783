import stopsearch from '../assets/stopsearch.png'
import stopsearchWall from '../assets/stopsearch-wall.jpg'
import io from '../assets/io.jpg';
import io2 from '../assets/io2.jpg';
import oxford from '../assets/oxford-social.jpeg';
import three from '../assets/three.gif';
import aiArt from '../assets/ai-art.jpg';
import mindGames from '../assets/mindgames.gif';
import nothing from '../assets/nothing.png';
import crinkle from '../assets/crinkle.png';
import stress from '../assets/stress.png';
import three2 from '../assets/three2.gif';
import kraftwerk from '../assets/kraftwerk.jpeg'
import posters from '../assets/posters.png'
import smiley from '../assets/smiley.png'

export const SliderData = [
  {
    'orientation': 'port',
    'image': aiArt
  },
  {
    'orientation': 'port',
    'image': smiley
  },
  {
    'orientation': 'port',
    'image': posters
  },
  {
    'orientation': 'land',
    'image': kraftwerk
  },
  {
    'orientation': 'land',
    'image': io
  },
  {
    'orientation': 'port',
    'image': stress
  },
  {
    'orientation': 'land',
    'image': three2
  },
  {
    'orientation': 'land',
    'image': oxford
  },
  {
    'orientation': 'land',
    'image': stopsearchWall
  },
  {
    'orientation': 'land',
    'image': io2
  },
  {
    'orientation': 'land',
    'image': three
  },
]
