import "./sidemenu.scss";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Hamburger from "../hamburger/hamburger";

import spotify from "../../assets/spotify.svg";
import insta from "../../assets/insta.svg";

let page, link, menuTitle; //Current page and link
let lastScrollTop = 0;
let fontSize = 60;

class SideMenu extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    page = window.location.pathname.split("/");
    link = document.getElementById(page[1]);
    link.style.color = "#1004FA";
  }

  scrollSize() {
    if (window.innerWidth > 900) return;
    menuTitle = document.getElementById("menu-title");
    var st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop) {
      if (window.scrollY >= 0 && fontSize > 42) {
        menuTitle.style.fontSize = (fontSize--).toString() + "px";
      }
    } else {
      if (window.scrollY <= 100 && fontSize < 60) {
        menuTitle.style.fontSize = (fontSize += 3).toString() + "px";
      }
    }
    lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollSize);
  }

  render() {
    window.addEventListener("scroll", this.scrollSize);
    return (
      <div className="menu">
        <Link to="/">
          <h2 id="menu-title">robin</h2>
        </Link>
        <Hamburger></Hamburger>
        <div className="links">
          <Link id="projects" to="/projects">
            <p>Projects</p>
          </Link>
          <Link id="about" to="/about">
            <p>About</p>
          </Link>
        </div>
        <div className="foot">
          <a href="https://github.com/robinojw">Github</a>
          <div className="icons">
            <a
              href="https://open.spotify.com/user/1119931584?si=hqPoITZTSZ2EvnQ1FXU4_Q"
              target="_blank"
            >
              <img src={spotify} alt="" />
            </a>
            <a href="https://www.instagram.com/robinojw/?hl=en" target="_blank">
              <img src={insta} alt="" />
            </a>
          </div>
        </div>
        <div />
      </div>
    );
  }
}

export default SideMenu;
