import "./casestudy.scss";
import React, { Component } from "react";
import HeaderImage from "./header-image/header-image";
import Tech from "./tech/tech";
import Subtitle from "./subtitle/subtitle";
import Content from "./content/content";

class CaseStudy extends Component {
  componentDidMount() {
    window.scrollTo({ top: 0 });
  }
  render() {
    return (
      <div className="casestudy">
        <HeaderImage data={this.props} />
        <div className="casestudy-container">
          <Subtitle data={this.props} />
          <Content data={this.props} />
        </div>
      </div>
    );
  }
}

export default CaseStudy;
