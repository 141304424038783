import "./hamburger.scss";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import ham from "../../assets/ham.svg";

let bool, opacity, enabled, index;

class Hamburger extends Component {
  bool = false;

  menu() {
    let menuElem = document.getElementById("menu");
    bool = !bool;
    opacity = bool === false ? "0" : "1";
    enabled = bool === false ? "none" : "all";
    index = bool === false ? "-1" : "10";
    menuElem.style.opacity = opacity;
    menuElem.style.pointerEvents = enabled;
    menuElem.style.zIndex = index;
  }

  render() {
    return (
      <div className="hamburger">
        <img onClick={this.menu} className="ham" src={ham} alt="" />
        <div id="menu" className="mobile-menu">
          <Link to="/">
            <p>Home</p>
          </Link>
          <Link to="/projects">
            <p>Projects</p>
          </Link>
          <Link to="/gallery">
            <p>Gallery</p>
          </Link>
          <Link to="/about">
            <p>About</p>
          </Link>
        </div>
      </div>
    );
  }
}

export default Hamburger;
