import "./slider.scss";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { SliderData } from "../../shared/slider_data";

let array = SliderData;

class Slider extends Component {
  render() {
    return (
      <div className="slider">
        {array.map((image) => (
          <img
            key={image}
            className={image.orientation}
            src={image.image}
            alt=""
          ></img>
        ))}
      </div>
    );
  }
}

export default Slider;
