import "./subtitle.scss";
import React, { Component } from "react";

let lastScrollTop = 0;
let subtitle;
let transform = 0;

class Subtitle extends Component {
  content = this.props.data.case;

  componentDidMount() {
    window.addEventListener("scroll", this.scrollSize);
    subtitle = document.getElementById("subtitle");
  }

  scrollSize() {
    var st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop) {
      subtitle.style.cssText =
        "transform: translateX(" + (transform -= 2).toString() + "vw)";
    } else if (transform < 0) {
      subtitle.style.cssText =
        "transform: translateX(" + (transform += 3).toString() + "vw)";
    }
    lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollSize);
  }

  render() {
    return (
      <div className="subtitle">
        <p id="subtitle">
          {this.content.case.subtitle}
          {this.content.case.subtitle}
          {this.content.case.subtitle}
          {this.content.case.subtitle}
          {this.content.case.subtitle}
          {this.content.case.subtitle}
          {this.content.case.subtitle}
          {this.content.case.subtitle}
        </p>
      </div>
    );
  }
}

export default Subtitle;
