import "./about.scss";
import React from "react";
import SideMenu from "../shared/sidemenu/sidemenu";
import smiley from "../assets/smiley.svg";

function About() {
  return (
    <div className="about">
      <SideMenu></SideMenu>
      <div className="spacer"></div>
      <div className="about-container">
        <div className="about-content">
          <div>
            <h2>
              I'm a Software Engineer, currently working at Peloton and based in London.
              My main focuses include, React/Next JS, Kotlin Microservices, SpringBoot, Build Systems and Python.
            </h2>
          </div>
          <div className="row">
            <p>
              After studying computer science at the University of East Anglia, I went on to work at Phantom.
              Phantom allowed me to work on Google products and projects as well as creating marketing 'experience'
              sites that gave me the chance to work with a number of different technologies and paradigms. I've now moved on
              to Peloton to persue a career in purely product focused development and have taken opportunity to start working on
              more back-end focused features, such as migrating our Monolith API over to a series of Microservices using Kotlin and Springboot.
            </p>
          </div>
          <a href="mailto:robinojw@gmail.com">Say Hello 👋</a>
        </div>
        <img src={smiley} alt="" />
      </div>
    </div>
  );
}

export default About;
